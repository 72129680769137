/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Header from "./includes/siteHeader";
import Footer from "./includes/siteFooter";
import "slick-carousel/slick/slick.css";
import "../../assets/css/custom.css";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet" />
      </Helmet>
      <div className="outerWrapper">
        <div className="mainContainer">
          <Header />
          {children}
        </div>
        <Footer />
        <CookieConsent
            location="bottom"
            buttonText="That's okay"
            cookieName="digitalavenuesCookieConsent"
            style={{ background: "#173753" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            <div className="cookieContWrap">
              <p>This website uses cookies to enhance the user experience.{" "}</p>
              <Link className="cookieMoreInfoBtn" to="/cookie-policy">More Info</Link>
            </div>
        </CookieConsent>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
