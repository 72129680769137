import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { globalHistory as history } from '@reach/router'

const Header = () => {

    const { location, navigate } = history;

    const [pageScrolled, setPageScrolled] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    useEffect(() => {
        const onScroll = e => {
            if (e.target.documentElement.scrollTop > 70) {
                setPageScrolled(true);
            }
            else {
                setPageScrolled(false);
            }
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [pageScrolled]);

    function openMobileMenu() {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    //console.log(pageScrolled)

    function ourWorkActiveClass(){
        let _className = '';
        let pathnameArray = location.pathname.split("/");
        if(pathnameArray.length>=2){
            if(pathnameArray[1] == 'our-work'){
                _className = 'active';
            }
        }

        return _className;
    }

    return (
        <header className={"mainHeader " + (pageScrolled ? 'pageScrolled' : '') + (mobileMenuOpen ? ' active' : '')}>
            <h1 className="logo"><Link to="/"></Link></h1>
            <span className="mobileNav" onClick={openMobileMenu} />
            <span className="closeNav" onClick={openMobileMenu} />
            <nav>
                <ul>
                    <li><Link to="/" activeClassName="active">HOME</Link></li>
                    <li><Link className={ourWorkActiveClass()} to="/our-work" activeClassName="active">Our Work</Link></li>
                    <li><Link to="/our-approach" activeClassName="active">Our Approach</Link></li>
                    <li><Link to="/about-us" activeClassName="active">About Us</Link></li>
                    <li><Link to="/careers" activeClassName="active">Careers</Link></li>
                    <li><Link to="/contact-us" activeClassName="active">Contact</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
