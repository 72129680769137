import { Link } from "gatsby";
import React from "react";

const Footer = () => (
    <footer className="mainFooter">
        <div className="footerTop">
            <div className="wrapper">
                <div className="footerLeft">
                    <div className="gpwLogo">
                        <a href="//www.greatplacetowork.com/" target="_blank"><img src="//res.cloudinary.com/digital-avenues/image/upload/v1675666905/digitalavenues.com/cirtificate-badge.png" alt="Great Place To Work" /></a>
                    </div>
                </div>
                <div className="footerRight">
                    <section>
                        <div>133A Dr. Meghnad Saha Sarani</div>
                        <div>Kolkata 700 029</div>
                        <div>West Bengal, India</div>
                        <div className="emailLink"><a href="mailto:biz@digitalavenuesinfotech.in">biz@digitalavenuesinfotech.in</a></div>
                    </section>
                </div>
            </div>
        </div>
        <div className="footerBottom">
            <div className="wrapper">
                <div className="footerLeft">
                    <div><strong>&copy; {new Date().getFullYear()} Digital Avenues</strong></div>
                    <div><Link to="/privacy-policy">Privacy Policy</Link>  &bull;  <Link to="/terms">Terms &amp; Conditions</Link></div>
                </div>
                <div className="footerRight">
                    <ul className="footerNav">
                        <li><Link to="/">HOME</Link></li>
                        <li><Link to="/our-work">Our Work</Link></li>
                        <li><Link to="/our-approach">Our Approach</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                        <li><Link to="/contact-us">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

)

export default Footer
